import { IndexPageVariant, InstallationStep, OS } from '../models/indexPageVariant';

const items: InstallationStep[] = [
  {
    text: 'Download xpoint-web-sdk.dmg file and open by double click. Installation dialog will appear.',
    image: '',
    imageWidth: ''
  },
  {
    text: 'Drag the Xpoint Verify icon over to the Applications folder.',
    image: '../../content/mac/mac-step-2.png',
    imageWidth: '600px'
  },
  {
    text:
      'Xpoint Verify is now installed and can be opened. You can find it by clicking on the Launchpad icon in your' +
      ' Dock, or by searching for it using Spotlight. Please find and open it by clicking on the icon.',
    image: '../../content/mac/mac-step-3.png',
    imageWidth: '417px'
  },
  {
    text:
      'When you open the application for the very first time after downloading it from the Internet, you might' +
      " see a little warning message. This message is just there to make sure you're aware that the app came" +
      " from the Internet and wasn't directly from the App Store. Please click Open button to proceed.",
    image: '../../content/mac/mac-step-4-new.png',
    imageWidth: '318px'
  }
];

export const macVariant: IndexPageVariant = {
  id: OS.MAC,
  name: 'Mac',
  installationSteps: items,
  downloadLink: 'xpoint-web-sdk.dmg'
};
