import { IndexPageVariant, InstallationStep, OS } from '../models/indexPageVariant';

const items: InstallationStep[] = [
  {
    text: 'Launch xpoint-web-sdk.exe. This setup requires administrative privileges.',
    images: [
      { src: '../../content/windows/win-step-1-1-new.png', width: '714px' },
      { src: '../../content/windows/win-step-1-2-new.png', width: '456px' }
    ]
  },
  {
    text: 'The installation will proceed without any configuration or confirmation steps needed.',
    image: '../../content/windows/win-step-2-new.png',
    imageWidth: '598px'
  },
  {
    text: 'Click Finish to complete the setup. The application will immediately start once installation is completed.',
    image: '../../content/windows/win-step-3-new.png',
    imageWidth: '598px'
  }
];

export const windowsVariant: IndexPageVariant = {
  id: OS.WINDOWS,
  name: 'Windows',
  installationSteps: items,
  downloadLink: 'xpoint-web-sdk.exe'
};
